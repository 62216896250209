<template>
    <nav id="navbar" class="navbar navbar-expand-lg navbar-dark active-pointer-item container">
      <b-navbar-nav class="justify-content-end">
        <b-nav-item @click="routeChange('/')">Local Law 97</b-nav-item>
      </b-navbar-nav>
      <!-- <b-nav-item @click="routeChange('/')" toggleable="lg" variant="info">Scout</b-nav-item> -->

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="justify-content-end">
        <b-navbar-nav>
          <b-nav-item href="#" v-for="navItem in navLinks" :key="navItem.name" @click="routeChange(navItem.path)">{{navItem.name}}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </nav>
</template>
<script>

export default {
  data () {
    return {}
  },
  computed:{
    navLinks(){
      return this.$router.options.routes.filter(function(d){
        if(d.link === true){
          return d
        }
      })
    }
  },
  methods: {
    routeChange: function (route) {
      this.$router.push(route)
    }
  }
}
</script>
<style lang="scss">
.navbar-brand{
  font-size: $font-lg;
  font-weight: $font-light;
  letter-spacing: -0.48px;
}
.nav-item{
  font-size: $font-md;
  font-weight: $font-light;
  line-height: 2.25;

  .nav-link{
    color: $white!important;

    &:hover{
      color: $highlight-color!important;
    }
  }
}
.navbar-nav{
  background: #181818;
}
.navbar{
  position: absolute!important;
  top: 0;
  max-width: 100%!important;

  z-index: 1;

  background-color: $black;
  color: $white;

  pointer-events: none;

  max-height: $navbar-height;
}
</style>
