<template>
  <div id="app">
    <navbar></navbar>
    <router-view/>
  </div>
</template>

<style lang="scss">

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow:hidden;
}
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500&display=swap');
// basic html overrides
@import "styles/basics";
// layout
@import "styles/layout.scss";
// font styling
@import "styles/typography.scss";

#app {
  top:0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;

  color: $off-white;

  height: 100vh;

  background: $black;
}
</style>

<script>
import navbar from '@/components/navbar.vue'

export default{
  name: 'app',
  components:{ navbar }
}
</script>