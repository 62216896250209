<template>
  <div id="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>
    <div id="sidebar-container" class="text-left frosted-glass" v-if="testIsMobile === false">
      <div class="container">
        <!-- <div class="row mt-4">
          <div class="col-12 p-4">
            <h3>Local Law 97</h3>
            <small>New York, NY</small>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-12 p-4">
            <h4>Zoom to Address in New York</h4>
              <vue-google-autocomplete
                  id="map"
                  classname="form-control"
                  placeholder="Start typing"
                  v-on:placechanged="getAddressData"
              >
              </vue-google-autocomplete>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12 p-4">
            <h4>Address Selected</h4>
            <div>{{address}}</div>
          </div>
        </div> -->
        <hr>
        <div class="row mt-2">
          <div class="col-12 ">
            <div class="d-flex justify-content-between align-items-center">
              <h4>Total Fine</h4><h3>{{ti['total_fines']}}</h3>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="d-flex justify-content-between align-items-center">
              <h4>2024 Fine per year</h4>
              <h3>{{ti['2024_year_fine']}}</h3>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <div>{{(Math.round(ti['total_GHG'] / ti['2024_max']) * 10 )/10 === NaN ? 0 : (Math.round(ti['total_GHG'] / ti['2024_max']) * 10 )/10 }} % over 2024 limit</div>
            </div> 
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="d-flex justify-content-between align-items-center">
              <h4>2030 Fine per year</h4>
              <h3>{{ti['2030_year_fine']}}</h3>
            </div>
            <div>
              <div>{{ (Math.round(ti['total_GHG'] / ti['2030_max']) * 10 )/10      }} % over 2030 limit</div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-2">
          <div class="col-12">
            <h4>Total Fines by Year</h4>
          </div>
        </div>
        <div class='row mt-2' id="chart">
          <div class="col-12">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
        <hr>
        <div class="row mt-2">
          <div class="col-12">
            <h4>Map Legend</h4>
            <small>Lots colored by total fines</small>
            <canvas id="legendBox" width="450" height="15"></canvas>
            <div class="d-flex justify-content-between mt-1" style="width: 450;">
              <small>$0</small><small>$20 million</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mobile-button" @click="bottomBarActive = !bottomBarActive" v-if="testIsMobile === true">Info</div> -->
    <div id='mobile-sidebar-container' v-if="testIsMobile === true">
      <div class="mobile-button" @click="bottomBarActive = !bottomBarActive" :class="{'active': bottomBarActive === true, 'inactive': bottomBarActive === false}">Info</div>
      <div class="mobile-panel-container frosted-glass" :class="{'active': bottomBarActive === true, 'inactive': bottomBarActive === false}">
        <div class="row mt-2">
          <div class="col-12">
            <h3>Total Fine 2024 - 2035</h3>{{ti['total_fines']}}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h4>Map Legend</h4>
            <small>Lots colored by total fines</small>
            <canvas id="legendBox" width="300" height="15"></canvas>
            <div class="d-flex justify-content-between mt-1">
              <small>$0</small><small>$20 million</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

import navbar from "@/components/navbar.vue";

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

// google template
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// store import
import { mapGetters } from 'vuex'
// formater importer 
import { format } from 'd3-format'
// csv importer
import { csv, json } from 'd3-fetch'
// scale linear 
import { scaleLinear } from 'd3-scale'
// map 
var map = null
// var marker = null
// formatter for million
var millionFormat = format('.2s')
// popup place holader
var popup = null
// data holder
var ll84_data = null
var building_footprints = null

var featureID = null

import apexchart from 'vue-apexcharts'

export default {
  name: 'Map',
  data () {
    return {
      // MAPBOX LIGHT STYLE TOKEN AND URL WITH BASIC SETTINGS
      // accessToken:
      //   'pk.eyJ1IjoiZW1wdHlib3gtZGVzaWducyIsImEiOiJjanBtM3E3ajAwbDF0NDJsa2N0OWh4M3p0In0.ZhciPKsk9UUSjUN44kJrcw',
      // mapStyle: 'mapbox://styles/emptybox-designs/cjpq0tslf1asv2srjg6zdwf6k',
      accessToken:
        'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/mapbox/dark-v10',
      zoom: 12,
      center: [-73.96, 40.76],
      address: ' ',
      bottomBarActive: false,
      mapColors: ['#4BEFC0','#35D4AF','#27AB9A','#187081','#202528'],
      // boroughNameDict: {
      //   'BK': 'Brooklyn',
      //   'BX': 'Bronx',
      //   'MN': 'Manhattan',
      //   'QN': 'Queens',
      //   'SI': 'Staten Island'
      // },
      ti: {},
      series: [{
        name: 'Total Fines',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: false,
          // formatter: function (val) {
          //   return val + "%";
          // },
          // offsetY: -10,
          // style: {
          //   fontSize: '12px',
          //   colors: ["#304758"]
          // }
        },
        tooltip:{
          enabled: true,
          theme: 'dark'
        },
        xaxis: {
          categories: ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
            color: "#fff"
          },
          // crosshairs: {
          //   fill: {
          //     type: 'gradient',
          //     gradient: {
          //       colorFrom: '#D8E3F0',
          //       colorTo: '#BED1E6',
          //       stops: [0, 100],
          //       opacityFrom: 0.4,
          //       opacityTo: 0.5,
          //     }
          //   }
          // },
          tooltip: {
            enabled: false,
          },
          labels:{
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return "$" + millionFormat(val)
            }
          }
        
        }
      }
    }
  },
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    VueGoogleAutocomplete,
    apexchart
  },
  created () {

    // ATTACHES MAP TO INSTANCE
    this.mapbox = Mapbox

    csv("/Data/ll84_building_ll84_join.csv").then(function(data) {

      ll84_data = data

    })
    // .then(function(response){
    //   json("/Data/building_footprints.json").then(function(buildingFootprints){

    //     building_footprints = buildingFootprints
    //     // console.log("created -> building_footprints", building_footprints)

    //     var colorArray = []
    //     var colorScale = scaleLinear()
    //     // .range(['#009efd','#2af598'])
    //     .range(['#4BEFC0','#35D4AF','#27AB9A','#187081','#202528'])

    //     building_footprints.features.forEach((d)=>{
    //       colorArray.push(d['properties']['total_fines'])
    //     })

    //     colorScale.domain(colorArray)

    //     building_footprints.features.forEach((d)=>{
    //       d['properties']['total_fines_color'] = colorScale(d['properties']['total_fines'])
    //     })

    //     console.log(building_footprints)
    //   })
    // })
  },
  mounted () {},
  computed: {
    ...mapGetters({}),
    // returns if the screen is a mobile devive as true or false
    testIsMobile: function () {
      var width = window.innerWidth
      var isMobile
      if (width < 992) {
        isMobile = true
      } else {
        isMobile = false
      }
      return isMobile
    },
  },
  methods: {
    flyTo (coords, zoom) {
      map.flyTo({
        center: [coords[0], coords[1]],
        zoom: zoom,
        speed: 1,
        curve: 1,
        easing (t) {
          return t
        }
      })
    },

    // FIRES ON MAP LOAD
    onMapLoaded (event) {
      console.debug('map is loaded')

      map = event.map

      this.addBuildingFootprints(map)
      this.createMapLegend()
    },
    createMapLegend(){
      var c = document.getElementById("legendBox")
      var ctx = c.getContext("2d")

      var grd = ctx.createLinearGradient(0, 0, 300, 0)

      grd.addColorStop(0, this.mapColors[4]);
      grd.addColorStop(0.2, this.mapColors[3]);
      grd.addColorStop(0.5, this.mapColors[2]);
      grd.addColorStop(0.7, this.mapColors[1]);
      grd.addColorStop(1, this.mapColors[0]);

      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, 450, 15);
    },
    getAddressData (address) {
      // flys to location through ease function
      this.flyTo([address.longitude, address.latitude], 18)
      // adds map marker to map
      // this.addMapPoint([address.longitude, address.latitude], this.mapbox)

      this.address = address['street_number'] + ' ' + address['route'] + ' ' + address['locality'] + ' ' + address['administrative_area_level_1'] + ', ' + address['postal_code']

    },
    addBuildingFootprints(map){
      console.debug("add building footprints")

      // console.log(ll84_data)
      // console.log(building_footprints)

      map.addSource('building_footprints_data', {
        type: 'geojson',
        data: "../Data/building_footprints.geojson",
        generateId: true
        // data: building_footprints
      });

      map.addLayer({
        id: 'building_footprints',
        source: 'building_footprints_data',
        type: 'fill',
        paint: {
          "fill-color": ['get', 'total_fines_color'],
          "fill-opacity":[
            'case',
            ['boolean',
              ['feature-state', 'hover'],
              false
            ],
              1,
            0.5
          ],
        }
      })
      this.createMapTooltip('building_footprints')
    },
    createMapTooltip (mapLayer) {
      popup = new (this).mapbox.Popup({ closeButton: true, closeOnClick: true })

      map.on('click', mapLayer, (e) => {
        e.originalEvent.cancelBubble = true

        if (document.querySelector('.mapboxgl-popup') === null) {
          var prop = e.features[0]['properties']
          var t = null

          for (let d of ll84_data) {
            if (d['base_bbl'] === e.features[0]['properties']['base_bbl']) {
              t = d
              break;
            }
          }

          console.log(t)

          var count_2024 = parseFloat(t['2024_fine'])
          var count_2030 = parseFloat(t['2030_fine'])

          var graphY = []
          var total = 0

          for (let i = 2020; i < 2036; i++) {
            if(i < 2024){
              graphY.push(0)
            }
            if(i >= 2024 && i < 2030){
              total += count_2024
              graphY.push(total)
            }
            else if(i >= 2030){
              total += count_2030
               graphY.push(total)
            }
          }
          // reset graph y axis data
          this.series = []
          this.series = [{
            data: graphY
          }]

          this.ti = {
            "primary_use": t['ll84_Largest Property Use Type'],
            "primary_use_gfa": parseFloat(t['ll84_Largest Property Use Type - Gross Floor Area (ft²)']),
            "primary_use_multiplier":parseFloat(t['primary_2024_multiplier']),
            "secondary_use": t['ll84_2nd Largest Property Use Type'],
            "secondary_use_gfa":parseFloat(t['ll84_2nd Largest Property Use - Gross Floor Area (ft²)']),
            "secondary_use_multiplier":parseFloat(t['secondary_2024_multiplier']),
            "tertiary_use": t['ll84_3rd Largest Property Use Type'],
            "tertiary_use_gfa":parseFloat(t['ll84_3rd Largest Property Use - Gross Floor Area (ft²)']),
            "tertiary_use_multiplier":parseFloat(t['tertiary_2024_multiplier']),
            "2024_year_fine": "$" + millionFormat(parseFloat(t['2024_fine'])),
            "2030_year_fine": "$" + millionFormat(parseFloat(t['2030_fine'])),
            "total_fines":"$" + millionFormat(t['total_fines']),
            "total_gfa": millionFormat(t['ll84_Self-Reported Gross Floor Area (ft²)']),
            "total_GHG":t['ll84_Total GHG Emissions (Metric Tons CO2e)'],
            "2024_max": t['2024_Max'],
            "2030_max": t['2030_Max'],
          }

          // popup.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
          //   .setHTML("<div class='container' style='pointer-events:none;'><div class='row'><div class='col-12'><h4>"+t['name']+"</h4></div></div></div>")
          //   .addTo(map)
        }
      })

      map.on('mousemove', mapLayer, (e) => {
        map.getCanvas().style.cursor = 'pointer'

        if (e.features.length > 0) {
          if (featureID !== null) {
            map.setFeatureState(
              { source: 'building_footprints_data', id: featureID },
              { hover: false }
            )
          }

          featureID = e.features[0].id

          map.setFeatureState(
            { source: 'building_footprints_data', id: featureID },
            { hover: true }
          )
        }
      })

      map.on('mouseleave', mapLayer, (e) => {
        map.getCanvas().style.cursor = ''

        if (featureID !== null) {
          map.setFeatureState(
            { source: 'building_footprints_data', id: featureID },
            { hover: false }
          )
        }

        featureID = null
      })
    },
    addMapPoint(coords,mapbox){

      var markers = document.getElementsByClassName('map-marker')

      if(markers[0]){
        markers[0].remove()
      }

      geojson.features[0].geometry.coordinates = coords

      geojson.features.forEach(function(marker) {
        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = 'map-marker';

        // make a marker for each feature and add to the map
        new mapbox.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
      });
    }
  }
}
</script>

<style lang="scss">

$white: #fff;
$off-white:#EBEBF0;
$highlight-color:#3AB2E7;
$black: #333;
$off-black: #1A1A1A;
$frosted-color:rgba(26,26,26,0.45);
$grey: #C9D2D3;
$dark-grey: #383838;

html, body{
  height: 100%;
}

html {
  font-size: 12px;  // Magic:
}
hr{
  border-color: rgba(235,235,230, 0.4)!important;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

#Map {
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}
.mapboxgl-canvas {
  left: 0;
}
#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  overflow: hidden;

  // z-index: -1;;
}
#sidebar-container{
  position: absolute;
  top:$navbar-height;
  left: 1rem;
  width: 500px;
  max-height: calc(95vh - #{$navbar-height});

  padding: 1rem 0;

  color: $white;

  // background: white;
}

$mobile-height: 300px;

#mobile-sidebar-container{
  position: absolute;
  bottom:0px;
  left: 0;
  width: 100%;

  pointer-events: none;

  .mobile-panel-container{
    position: absolute;
    transition: bottom 0.4s ease-in;
    // background: white;
    height: $mobile-height;
    width: 100%;

    pointer-events: none;

    &.active{
      bottom: 0px;
    }
    &.inactive{
      bottom: -$mobile-height
    }
  }
}
.mobile-button{
  position: absolute;

  border-radius: 999px;
  background: $white;
  color: $dark-grey;
  padding: 1rem;
  left: 1rem;

  z-index: 999;

  transition: bottom 0.4s ease-in;
  pointer-events: all;

  &.active{
    bottom: calc(#{$mobile-height} - 20px);
  }
  &.inactive{
    bottom: 1rem;
  }
}
.map-marker {
  background-image: url("https://avatars2.githubusercontent.com/u/600935?s=400&v=4");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
// .mapboxgl-ctrl-group{
//   background: $dark-grey!important;
// }
.mapboxgl-ctrl{
  display: none!important;
}

#legendBox{
  border-radius: 4px;
}
.frosted-glass{
  background: $frosted-color;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
// .apexcharts-xaxis-label{
//   color:$white;
//   // stroke:$white;
// }
text{
  fill: $white!important;
}
</style>
